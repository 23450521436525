import BlogIndex from "@/views/static/blog/BlogIndex.vue";
import BlogView from "@/views/static/blog/BlogView.vue";
import LastMinuteReiseziel from "@/views/static/blog/posts/last-minute-reiseziel.vue";
import stressfreiesReisen from "@/views/static/blog/posts/stressfreies-reisen.vue";
import staedtetripWellness from "@/views/static/blog/posts/staedtetrip-wellness.vue";
import wanderwegeDeutschland from "@/views/static/blog/posts/wanderwege-deutschland.vue";
export const useBlogRoutes = () => {
  return [
    {
      path: "/blog",
      component: BlogView,
      children: [
        {
          path: "/",
          name: "touriBlog",
          component: BlogIndex
        },
        {
          path: "last-minute-reiseziele-kurzurlaube-deutschland",
          name: "last-minute-reiseziel",
          component: LastMinuteReiseziel
        },
        {
          path: "so-gelingt-perfekter-kurzurlaub-tipps-stressfreies-reisen",
          name: "stressfreies-reisen",
          component: stressfreiesReisen
        },
        {
          path: "staedtetrip-wellness-kombinieren-schoensten-destinationen",
          name: "staedtetrip-wellness",
          component: staedtetripWellness
        },
        {
          path: "die-schoensten-wanderwege-deutschland",
          name: "wanderwege-deutschland",
          component: wanderwegeDeutschland
        }
      ]
    }
  ];
};
