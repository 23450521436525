<template lang="pug">
  nav#top-nav
    .container-hd.position-relative(style="height:46px;z-index:1;")
      .d-flex.w-100.h-100.position-relative
        .d-flex.align-items-center.nav-container.txt-white.justify-content-between
          .d-flex.align-items-center.nav-items
            // nav items
            .nav-item.mx-2(v-for="navItem in navItems" :class="{active:navItem === activeItem}")
              router-link(v-if="navItem.route" :to="navItem.route" :title="navItem.description")
                img(v-if="navItem.logo.small" :src="navItem.logo.small")
              a(v-if="navItem.link" :href="navItem.link" :title="navItem.description")
                img(v-if="navItem.logo.small" :src="navItem.logo.small")

          .static-buttons.h-100.d-flex
            SocialLinkInstagram.mx-1.px-lg-1.px-2
            SocialLinkFacebook.mx-1.px-lg-1.px-2
            SocialLinkWhatsapp.mx-1.px-lg-1.px-2(text="Service")
            router-link.login.d-flex.align-items-center.mx-1.px-lg-1.px-2(:to="{name:'Account'}" v-if="user")
              font-awesome-icon.mr-0.mr-lg-2(icon="user")
              b.d-none.d-lg-inline-block {{ user.FirstName ? user.FirstName : user.LoginEmail }}
            a.login.d-flex.align-items-center.mx-1.px-lg-1.px-2.cursor-pointer(@click="showLoginModal" v-else)
              font-awesome-icon.mr-0.mr-lg-2(icon="user")
              b.d-none.d-lg-inline-block Login
            a.hotline.d-flex.align-items-center.cursor-pointer.mx-1.px-lg-1.px-2(@click="showHotlineModal")
              font-awesome-icon.mr-0.mr-lg-2(icon="phone")
        button.nav-toggle.d-flex.justify-content-center.align-items-center.no-style(v-b-toggle.mobile-top-nav :aria-expanded="hasExpandedMobileNav ? 'true' : 'false'" aria-controls="mobile-top-nav")
          img.branding.d-inline-block(src="https://s3-cloud.td-cdn.de/Images/Logos/touridat/branding/white/touridat-branding-white-50x50.png")
    // mobile nav
    b-collapse#mobile-top-nav.position-relative.w-100.bg-td-grey-lighter(v-model="hasExpandedMobileNav")
      .container-hd.text-center
        .container.py-4
          .h1.title.pt-3 Entdecken Sie die Reisewelt von touriDat:
          .row
            .col
              hr.mb-lg-5
          .row
            // nav items mobile
            .col-12.col-sm-6.col-lg-4(v-for="navItem in navItems")
              router-link.markbox.button-primary.txt-white.d-block(v-if="navItem.route" :to="navItem.route" :title="navItem.description" :class="`button-t${navItem.name.toLowerCase()}`")
                img(v-if="navItem.logo.small" :src="navItem.logo.large" :alt="`touri${navItem.name} - ${navItem.description}`")
                br
                span {{navItem.description}}
              a.markbox.button-primary.txt-white.d-block(v-if="navItem.link" :href="navItem.link" :title="navItem.description" :class="`button-t${navItem.name.toLowerCase()}`")
                img(v-if="navItem.logo.small" :src="navItem.logo.large" :alt="`touri${navItem.name} - ${navItem.description}`")
                br
                span {{navItem.description}}
          .row
            .col
              hr.mt-0
          //.h1.title.pt-3 Die touriDat Apps:
          //.d-inline-block
            .d-flex.align-items-center
              a.app-link.mx-1(v-for="appLink in appLinks" target="_blank" :href="appLink.url")
                font-awesome-icon.icon.mt-2(:icon="appLink.icon")
                .small {{appLink.label}}
</template>

<script>
import { WebTemplatesEnum } from "@/utils/WebTemplatesEnum.ts";
import { showHotlineModal } from "@/lib/components/hotlineModal";
import * as t from "@/store/main.json";
import LoginModal from "@/views/components/modals/loginModal/LoginModal.vue";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user-store";
import SocialLinkInstagram from "@/views/components/partials/buttons/socials/SocialLinkInstagram.vue";
import SocialLinkFacebook from "@/views/components/partials/buttons/socials/SocialLinkFacebook.vue";
import SocialLinkWhatsapp from "@/views/components/partials/buttons/socials/SocialLinkWhatsapp.vue";

export default {
  name: "TopNavigation",
  components: { SocialLinkWhatsapp, SocialLinkFacebook, SocialLinkInstagram },
  props: {
    templateType: {
      type: Number,
      default: WebTemplatesEnum.Default
    }
  },
  setup() {
    const hotline = t.main.hotlinePhone;

    return {
      hotline,
      appLinks: [
        {
          label: "App Store",
          icon: "fab fa-apple",
          url: "https://apps.apple.com/de/app/touridat-urlaub-hotels/id945676260"
        },
        {
          label: "Play Store",
          icon: "fab fa-google",
          url: "https://play.google.com/store/apps/details?id=com.touridat.android.webapp&hl=gsw\""
        }
      ],
      navItems: [
        {
          name: "Days",
          route: { name: "Home" },
          templateType: WebTemplatesEnum.Default,
          description: "Urlaubstage mit 100% Käuferschutz",
          logo: {
            small:
              "https://s3-cloud.td-cdn.de/Images/Logos/touridays/white-plain/only-mark/touridays-white-plain-only-mark-height-55.png",
            large:
              "https://s3-cloud.td-cdn.de/Images/Logos/touridays/white-plain/centered-horizontal-baseline/touridays-white-plain-centered-hotizontal-baseline-250x71.png"
          }
        },
        {
          name: "Tipps",
          route: { path: "/tipps" },
          templateType: WebTemplatesEnum.Tipps,
          description: "Hotelempfehlungen des Monats",
          logo: {
            small:
              "https://s3-cloud.td-cdn.de/Images/Logos/touritipps/white-plain/only-mark/touritipps-white-plain-only-mark-height-55.png",
            large:
              "https://s3-cloud.td-cdn.de/Images/Logos/touritipps/white-plain/centered-horizontal-baseline/touritipps-white-plain-centered-hotizontal-baseline-250x71.png"
          }
        },
        {
          name: "Deluxe",
          route: { path: "/deluxe" },
          templateType: WebTemplatesEnum.Deluxe,
          description: "Luxus-Offerten ab 4*S",
          logo: {
            small:
              "https://s3-cloud.td-cdn.de/Images/Logos/tourideluxe/white-plain/only-mark/tourideluxe-white-plain-only-mark-height-55.png",
            large:
              "https://s3-cloud.td-cdn.de/Images/Logos/tourideluxe/white-plain/centered-horizontal-baseline/tourideluxe-white-plain-centered-hotizontal-baseline-250x71.png"
          }
        },
        {
          name: "Golf",
          route: { path: "/golf" },
          templateType: WebTemplatesEnum.Golf,
          description: "Golferlebnisse der Extraklasse",
          logo: {
            small:
              "https://s3-cloud.td-cdn.de/Images/Logos/tourigolf/white-plain/only-mark/tourigolf-white-plain-only-mark-height-55.png",
            large:
              "https://s3-cloud.td-cdn.de/Images/Logos/tourigolf/white-plain/centered-horizontal-baseline/tourigolf-white-plain-centered-hotizontal-baseline-250x71.png"
          }
        },
        {
          name: "Blog",
          route: { path: "/blog" },
          templateType: WebTemplatesEnum.Blog,
          description: "Reiseblog für Inspiration",
          logo: {
            small:
              "https://s3-cloud.td-cdn.de/Images/Logos/touriblog/only-mark/touriblog-white-plain-only-mark-height-55.png",
            large:
              "https://s3-cloud.td-cdn.de/Images/Logos/touriblog/white-plain/basic/touriblog-white-plain-centered-horizontal-baseline-250x71.png"
          }
        },
        {
          name: "Maps",
          route: { path: "/karte" },
          templateType: WebTemplatesEnum.Map,
          description: "Kartensuche",
          logo: {
            small:
              "https://s3-cloud.td-cdn.de/Images/Logos/tourimaps/white-plain/only-mark/tourimaps-white-plain-only-mark-height-55.png",
            large:
              "https://s3-cloud.td-cdn.de/Images/Logos/tourimaps/white-plain/centered-horizontal-baseline/tourimaps-white-plain-centered-hotizontal-baseline-250x71.png"
          }
        },
        {
          name: "Book",
          link: "https://book.touridat.com/",
          description: "Anfragen & Buchen über touriBook",
          logo: {
            small:
              "https://s3-cloud.td-cdn.de/Images/Logos/touribook/white-plain/only-mark/touribook-white-plain-only-mark-height-55.png",
            large:
              "https://s3-cloud.td-cdn.de/Images/Logos/touribook/white-plain/centered-horizontal-baseline/touribook-white-plain-centered-hotizontal-baseline-250x71.png"
          }
        }
      ]
    };
  },
  data() {
    return {
      hasExpandedMobileNav: false
    };
  },
  computed: {
    ...mapState(useUserStore, ["user"]),
    activeItem() {
      try {
        // find the current active item matching templateType prop
        const activeItem = this.navItems.find(
          (navItem) => navItem.templateType === this.templateType
        );
        // if there is no matching item return the first one or undefined if there is no first item
        if (!activeItem) return this.navItems[0] || undefined;
        // return the matching item
        return activeItem;
      } catch (e) {
        this.$log.warn(e);
        return undefined;
      }
    }
  },
  watch: {
    $route() {
      this.hasExpandedMobileNav = false;
    }
  },
  methods: {
    showLoginModal() {
      this.$modal.show(
        LoginModal,
        {},
        {
          height: "auto",
          classes: ["modalMaxHeight", "rounded-0"]
        }
      );
    },
    showHotlineModal
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/mediaqueries.scss";

#top-nav {
  font-size: 17px;
  --bg-color: var(--theme-color-basic, #{$tdays-basic});
  background-color: var(--bg-color);

  .nav-container {
    flex: 0 0 calc(100% - 75px);

    a {
      color: inherit;
      text-decoration: none;
    }

    .static-buttons a {
      position: relative;
      height: 100%;

      b {
        font-weight: 500;
      }
    }

    .static-buttons a,
    .nav-items .nav-item {
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: var(--bg-color);
        position: absolute;
        left: 0;
        transform: scaleX(0);
        opacity: 0;
        bottom: -4px;
        transition: transform 0.25s, opacity 0.25s;
      }

      &:hover,
      &.active {
        &:after {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }

    .nav-items {
      @include sm {
        //margin-left:-0.5rem;
      }

      .nav-item {
        position: relative;

        img {
          height: 100%;
          width: auto;
          max-height: 24px;
        }

        a {
          display: block;
          padding: 10px 0;
        }

        &:not(.active) {
          display: none;
          @include md {
            display: block;
          }
        }
      }
    }
  }

  .nav-toggle {
    aspect-ratio: 1.63 / 1;
    background-color: rgba(0, 0, 0, 0.35);

    .branding {
      aspect-ratio: 1;
      width: 20px;
    }
  }

  #mobile-top-nav {
    z-index: 0;

    .app-link {
      aspect-ratio: 1;
      padding: 10px;
      text-decoration: none;
      transition: color 0.4s, box-shadow 0.4s;
      box-shadow: 0 0 0 $td-grey-light;
      border-radius: 5px;
      color: $td-grey-basic;

      .icon {
        font-size: 150%;
      }

      &:hover {
        color: $td-grey-darker;
        box-shadow: 0 0 5px $td-grey-light;
      }
    }

    .title {
      font-size: 20px;
      font-weight: 500;

      @include md {
        font-size: 28px;
      }
    }

    .markbox {
      margin-bottom: 15px;
      @include md {
        margin-bottom: 30px;
      }
      padding: 16px;
      font-size: 14px;
      height: 120px;

      img {
        width: auto;
        height: 100%;
        max-height: 45px;
      }

      span {
        display: inline-block;
        max-width: 120px;
      }
    }
  }
}
</style>
