<template lang="pug">
header.content-header
  .container
    .pt-4
      .d-flex.justify-content-between
        .d-flex
          .pr-3.mr-3.logo-holder
            .category-logo
              img.img-fluid.w-100(:src="categoryLogoUrl" :class="{invisible:!hasLoadedCategoryLogo}" @load="hasLoadedCategoryLogo = true")
          a.cursor-pointer.text-decoration-none(@click="showSealsModal(['winner','touriDays','trustami'])")
            .smaller(v-for="point in points")
              font-awesome-icon.check-icon.mr-1(icon="check")
              | {{point}}
        .d-flex
          .trustami

          .award Ipsum
      template(v-if="showsNavbar")
        hr
        .d-flex.mb-3
          nav#category-navigation.w-100
            Navbar(base-route-name="Category")
</template>
<script>
import {getCategoryName} from "@/lib/helper/router";
import Navbar from "@/views/components/navbar/Navbar";
import {showSealsModal} from "@/lib/components/sealsModal";
import mainData from "@/store/main.json";
export default {
  name: "ContentHeader",
  components:{
    Navbar
  },
  props:{
    showsNavbar:{
      type:Boolean,
      default:true
    }
  },
  setup(){
    const categoryLogos = {
      days:"https://s3-cloud.td-cdn.de/Images/Logos/touridays/grey-colored/left-sided-hotizontal-baseline/touridays-grey-colored-left-sided-horizontal-baseline-250x69.png",
      golf:"https://s3-cloud.td-cdn.de/Images/Logos/tourigolf/grey-colored/left-sided-hotizontal-baseline/tourigolf-grey-colored-left-sided-horizontal-baseline-250x69.png",
      tipps:"https://s3-cloud.td-cdn.de/Images/Logos/touritipps/grey-colored/left-sided-hotizontal-baseline/touritipps-grey-colored-left-sided-horizontal-baseline-250x69.png",
      blog: "https://s3-cloud.td-cdn.de/Images/Logos/touriblog/touriBlog.png",
      get default(){
        return this.days
      }
    }

    const points = [
        'geprüfter Testsieger seit 2018',
        '100% Käuferschutz',
        `über ${mainData?.main?.trustamiVotes} positive Bewertungen`
    ]

    return {categoryLogos,points}
  },
  data(){
    return {
      hasLoadedCategoryLogo:false
    }
  },
  computed:{
    categoryLogoUrl(){
      try {
        const categoryName = getCategoryName(this.$route);
        if(!categoryName) return this.categoryLogos.default;
        return this.categoryLogos[categoryName] || this.categoryLogos.default;
      } catch (e) {
        this.$log.error(e);
        return undefined;
      }
    }
  },
  methods:{
    showSealsModal
  },
  created(){
    console.log(this.categoryName);
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
.content-header {
  .category-logo {
    aspect-ratio:3.62 / 1;
    height:45px;
  }
  .logo-holder {
    border-right:1px solid $td-grey-lighter;
  }
  .check-icon {
    color:var(--theme-color-basic,$tdays-basic);
  }
}
</style>
