import {
    faEnvelope,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCircle,
    faTimes,
    faTools,
    faUser,
    faClone,
    faPlus,
    faTrash,
    faShoppingBasket,
    faShoppingCart,
    faSearchLocation,
    faTag,
    fas,
    faLink,
} from "@fortawesome/free-solid-svg-icons";

import {
    faPaypal,
    faAmazon,
    faApple,
    faGoogle,
    faInstagram,
    faFacebook,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

export const icons = [
    faEnvelope,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCircle,
    faTimes,
    faTools,
    faUser,
    faClone,
    faPlus,
    faTrash,
    faShoppingBasket,
    faShoppingCart,
    faSearchLocation,
    faTag,
    fas,
    faPaypal,
    faAmazon,
    faApple,
    faGoogle,
    faInstagram,
    faFacebook,
    faWhatsapp,
    faLink
]
