<script>

export default {
  name: "BlogIndex",
  data() {
    return {
      teaserOffset: 0
    };
  },
  methods: {
    trackTeaserOffset() {
      const scrollY = window?.scrollY || 75;
      if (scrollY <= 75) return;
      this.teaserOffset = scrollY - 75;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.trackTeaserOffset);
  },
  unmounted() {
    window.removeEventListener("scroll", this.trackTeaserOffset);
  },
  destroyed() {
    window.removeEventListener("scroll", this.trackTeaserOffset);
  },
  metaInfo() {
    return {
      title: "touriBlog | touriDat Reiseblog – Inspiration, Tipps & Reiseberichte",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Entdecke den touriDat Reiseblog! Spannende Berichte, Insidertipps und Inspirationen für deinen nächsten Traumurlaub. Jetzt lesen und dein Abenteuer starten! | Testsieger DtGV✔ 100% Käuferschutz✔"
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: "touriBlog, touriDat Blog, Reiseinspiration, Reiseberichte, Urlaubstipps, Geheimtipps, Reiseplanung, Abenteuer, Traumziele"
        },
        {
          vmid: "og:title",
          name: "og:title",
          content:
            "touriBlog | touriDat Reiseblog – Inspiration, Tipps & Reiseberichte | touriDat.com"
        },
        {
          vmid: "og:description",
          name: "og:description",
          content:
            "Entdecke den touriDat Reiseblog! Spannende Berichte, Insidertipps und Inspirationen für deinen nächsten Traumurlaub. Jetzt lesen und dein Abenteuer starten! | Testsieger DtGV✔ 100% Käuferschutz✔"
        }
      ]
    };
  }
};
</script>

<template lang="pug">
  #tblog
    .teaser.d-flex.align-items-end(:style="`--parallax-offset:${(teaserOffset)}px;`")
      .title.text-center.w-100.txt-white
        h1.h1 touriDat Reiseblog
        //h6.h4.d-inline-flex.align-items-center
          // span Deine Quelle für Reiseinspiration, Tipps und spannende Geschichten
    .container.my-5
      h2.text-center.w-100.mb-5 Erlebe die Welt mit touriDat: Dein Reiseblog für Inspiration und Tipps
      p.txt-white Willkommen auf dem offiziellen Blog von touriDat! Tauche ein in spannende Reiseberichte, wertvolle Insidertipps und inspirierende Geschichten aus Deutschland und seinen Nachbarländern. Unser Blog ist dein Begleiter, um die schönsten Orte zu entdecken, die besten Reiseangebote zu finden und dich optimal auf dein nächstes Abenteuer vorzubereiten. Ob Wochenendtrip, Fernreise oder Entspannung in der Natur – hier findest du alles, was dein Reiseherz begehrt.
      p.txt-white Lass dich inspirieren und entdecke, warum das Reisen mit touriDat so besonders ist! Erfahre mehr über geheime Reiseziele, kulturelle Highlights und praktische Ratschläge, die deine Reise unvergesslich machen.
      h3.h3.text-center.mb-4.mt-4 Unsere neuesten Blogbeiträge
      .row.row-cols-1.row-cols-md-2
        .col.mb-4
          .card.blog-card
            img.card-img-top(src="https://s3-cloud.td-cdn.de/Images/moloss/blog/posts/touriblog-stressfreies-reisen.jpg" alt="Stressfreies Reisen")
            .card-body
              h4.card-title So gelingt der perfekte Kurzurlaub: Tipps für stressfreies Reisen
              p.card-text  Die Reisevorbereitung und das Reisen müssen nicht stressig sein.🧳 Bei touriDat finden Sie hilfreiche Tipps für einen sorgenfreien Kurzurlaub. Jetzt lesen!
              router-link.button.button-primary.button-tblog.btn-block(:to="{ name: 'stressfreies-reisen'}") lesen
        .col.mb-4
          .card.blog-card
            img.card-img-top(src="https://s3-cloud.td-cdn.de/Images/moloss/blog/posts/touriblog-last-minute-kurzurlaub-deutschland.jpg" alt="Last Minute Kurzurlaub")
            .card-body
              h4.card-title Last-Minute-Reiseziele für Kurzurlaube in Deutschland
              p.card-text  Spontane Kurztrips für Paare, die unvergessliche Erlebnisse suchen. Entdecken Sie die besten Ziele in Deutschland und sichern Sie sich Ihre Auszeit! 🌟
              router-link.button.button-primary.button-tblog.btn-block(:to="{ name: 'last-minute-reiseziel'}") lesen
        .col.mb-4
          .card.blog-card
            img.card-img-top(src="https://s3-cloud.td-cdn.de/Images/moloss/blog/posts/touriblog-staedtetrip-wellness.jpg" alt="Städtetrip Wellness")
            .card-body
              h4.card-title Städtetrip und Wellness kombinieren: Die schönsten Destinationen
              p.card-text Erleben Sie den perfekten Mix aus Städtereise und Wellness. 🌟 Entspannen Sie in München, Dresden oder Hamburg mit einem Hotelgutschein von touriDat.
              router-link.button.button-primary.button-tblog.btn-block(:to="{ name: 'staedtetrip-wellness'}") lesen
        .col.mb-4
          .card.blog-card
            img.card-img-top(src="https://s3-cloud.td-cdn.de/Images/moloss/blog/posts/wanderwege-deutschland.jpg" alt="Wanderwege Deutschland")
            .card-body
              h4.card-title Die schönsten Wanderwege in Deutschland
              p.card-text Entdecken Sie die schönsten Wanderwege Deutschlands! 🥾 Von den Alpen in Bayern bis zu den Weinbergen an der Mosel – planen Sie jetzt Ihren Aktivurlaub! ➡️
              router-link.button.button-primary.button-tblog.btn-block(:to="{ name: 'wanderwege-deutschland'}") lesen
</template>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/mediaqueries.scss";
@import "@/assets/styles/blog.scss";

#tblog {

  h3 {
    font-size: 18px;
  }

  .blog-card img {
    height: 200px;
    object-fit: cover;
  }

  .blog-card {

    .card-title, .card-text {
      color: $td-grey-dark !important;
    }

    .card-title {
      font-size: 1rem;
    }

    .card-text {
      font-size: 0.9rem;
    }
  }

  .teaser {
    position: relative;
    width: 100%;
    height: 65vh;
    background-image: url("https://s3-cloud.td-cdn.de/Images/moloss/blog/touriblog_hero_image.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;

    &:after {
      pointer-events: none;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-image: linear-gradient(10deg, $tblog-darker 0.25%, transparent, transparent);
      z-index: 0;
    }

    & > * {
      position: relative;
      z-index: 1;
    }

    .title {
      margin-bottom: calc(var(--parallax-offset) * 0.05);
      color: white;

      .h4 {
        font-size: 1.125rem;
      }

      .h1 {
        font-size: clamp(2rem, 7vw, 3.25rem);
      }
    }
  }
}
</style>
