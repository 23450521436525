<template lang="pug">
  #mainNav
    #mnwrap
      .mn
        .container
          .row
            .col
              .float-left
                .mnlogo.d-none.d-md-inline-block
                  template
                    router-link.d-block(:to="{path:'/golf'}" v-if="type === WebTemplatesEnum.Golf")
                      img(src="https://s3-cloud.td-cdn.de/Images/Logos/tourigolf/grey-colored/left-sided-hotizontal-baseline/tourigolf-grey-colored-left-sided-horizontal-baseline-250x69.png" alt="touriGolf | Golferlebnisse der Extraklasse")
                    router-link.d-block(:to="{path:'/tipps'}" v-else-if="type === WebTemplatesEnum.Tipps")
                      img(src="https://s3-cloud.td-cdn.de/Images/Logos/touritipps/grey-colored/left-sided-hotizontal-baseline/touritipps-grey-colored-left-sided-horizontal-baseline-250x69.png" alt="touriTipps | Hotelempfehlungen des Monats")
                    router-link.d-block(:to="{path:'/deluxe'}" v-else-if="type === WebTemplatesEnum.Deluxe")
                      img(src="https://s3-cloud.td-cdn.de/Images/Logos/tourideluxe/grey-colored/left-sided-hotizontal-baseline/tourideluxe-grey-colored-left-sided-horizontal-baseline-250x69.png" alt="touriDeluxe | Luxus-Offerten ab 4*")
                    router-link.d-block(:to="{ name: 'Map' }" v-else-if="type === WebTemplatesEnum.Map")
                      img(src="https://s3-cloud.td-cdn.de/Images/Logos/tourimaps/grey-colored/left-sided-horizontal-baseline/tourimaps-grey-colored-left-sided-horizontal-baseline-250x69.png" alt="touriMaps | Kartensuche")
                    router-link.d-block(:to="{ name: 'touriBlog' }" v-else-if="type === WebTemplatesEnum.Blog")
                      img(src="https://s3-cloud.td-cdn.de/Images/Logos/touriblog/touriBlog.png" alt="touriBlog | Dein Reiseblog für Inspiration und Tipps")
                    router-link.d-block(:to="{ name: 'Home' }" v-else)
                      img(src="https://s3-cloud.td-cdn.de/Images/Logos/touridays/grey-colored/left-sided-hotizontal-baseline/touridays-grey-colored-left-sided-horizontal-baseline-250x69.png" alt="touriDays | mit 100% Käuferschutz")
                .mnmobile.d-inline-block.pl-md-2.ml-md-2.pl-lg-3.ml-lg-3(@click="showSealsModal(['winner','touriDays','trustami'])")
                  span.d-inline-block
                    font-awesome-icon(icon="check")
                    | &nbsp;geprüfter Testsieger seit 2018
                  br
                  span.d-inline-block
                    font-awesome-icon(icon="check")
                    | &nbsp;100% Käuferschutz
                  br
                  span.d-inline-block
                    font-awesome-icon(icon="check")
                    | &nbsp;über {{trustamiVotes}} positive Bewertungen
              .float-right.d-flex.align-items-center
                span.d-none.d-xl-inline-block
                  .trustpilot-widget(data-locale="de-DE", data-template-id="5419b637fa0340045cd0c936", data-businessunit-id="5fb25ba2e28cd80001f22278", data-style-height="20px", data-style-width="100%", data-theme="light", data-text-color="#333333")
                    a(href="https://de.trustpilot.com/review/touridat.com", target="_blank", rel="noopener") Trustpilot
                span.d-inline-block(style="cursor:pointer;" @click="showSealsModal(['winner','trustami'])")
                  .mntrustami.d-none.d-md-inline-block
                    .widget_container_badge
                  .mnwinner2018.d-none.d-md-inline-block.pr-md-2.mr-md-0.ml-md-1.pr-lg-3.mr-lg-2.ml-lg-2
                    img(src="https://s3-cloud.td-cdn.de/Images/makaira/marketing/DtGV/2022/Testsieger_Gesamt_quer.png" alt="touriDat | Testsieger seit 2018")
                .button-box-right-sided.d-inline-block
                  span.button.button-icon.button-secondary.button-td-grey(@click="openInfoModal")
                    font-awesome-icon(icon='fa-info')
                  span.button.button-icon.button-primary.button-tdays.d-inline-block.d-lg-none.openmno(@click="showsMobileNav = true" v-if="!hasSearchButtonSlot")
                    img#search-cat-icon.lozad(src="https://s3-cloud.td-cdn.de/Images/makaira/search_categorys.png" alt="nav" style="pointer-events:none;")

                  .search-button.button.button-icon.button-primary.button-tdays.d-lg-inline-block.d-none(@click="search")
                    font-awesome-icon(icon='fa-search')
                  slot(name="additionalButtons")
        .container.d-none.d-lg-block
          hr
        .container.d-none.d-lg-block
          .row
            .col
              nav.mncattree
                Navbar(:base-route-name="baseRouteName" v-if="showsNavbar")
    #mno.overlay.bg-page(:class="{collapsed:!showsMobileNav}")
      .container(@click="closeMobileNavOnLink")
        NavbarMobile(:base-route-name="baseRouteName" @close="showsMobileNav = false")
</template>

<script>
import Navbar from "../navbar/Navbar";
import { WebTemplatesEnum } from "@/utils/WebTemplatesEnum.ts";
import EventBus from "@/event-bus";
import ProcedureInfoModal from "@/views/components/modals/procedureInfo/ProcedureInfoModal";
import NavbarMobile from "@/views/components/navbar/NavbarMobile";
import { showSealsModal } from "@/lib/components/sealsModal";
import ShoppingBasketButton from "@/views/components/shoppingBasket/ShoppingBasketButton.vue";
import mainData from "@/store/main.json";

export default {
  name: "MainNav",
  components: { ShoppingBasketButton, NavbarMobile, Navbar },
  props: {
    type: Number,
    baseRouteName: {
      type: String,
      default: "Category"
    },
    showsNavbar: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return { WebTemplatesEnum, trustamiVotes: mainData?.main?.trustamiVotes };
  },
  watch: {
    showsMobileNav(val) {
      if (val) {
        document.body.classList.add("no-scroll");
        return;
      }
      document.body.classList.remove("no-scroll");
    },
    $route() {
      this.showsMobileNav = false;
    }
  },
  mounted() {
    EventBus.$on(
      "changeWebTemplateType",
      function(type) {
        this.currentType = type;
      }.bind(this)
    );
    EventBus.$on(
      "updateShoppingBasketQuantity",
      function(quantity) {
        this.shoppingBasketQuantity = quantity;
      }.bind(this)
    );
  },
  data() {
    return {
      showsMobileNav: false
    };
  },
  computed: {
    hasSearchButtonSlot() {
      return !!this.$slots.searchButton;
    },
    hasLogoSlot() {
      return !!this.$slots.mnlogo;
    }
  },
  methods: {
    showSealsModal,
    search() {
      this.$router.push({ name: "Search" });
    },
    closeMobileNavOnLink(e) {
      if (e.target.matches("a[href],.search-input button"))
        this.showsMobileNav = false;
    },
    openShoppingBasket() {
      try {
        EventBus.$emit("updateShoppingBasket", true);
      } catch (e) {
        console.log(e);
      }
    },
    openInfoModal() {
      try {
        this.$modal.show(
          ProcedureInfoModal,
          {},
          {
            height: "auto",
            classes: ["modalMaxHeight", "rounded-0"]
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#mainNav {
  position: relative;

  &:hover {
    z-index: 10;
  }
}
</style>
